//
// Modal
//

.modal-rounded {
	border-radius: $modal-content-border-radius !important;	
}

.modal-header{
	justify-content: space-between;

	.fw-bold{
		margin-bottom: 0;
	}
}