//
// Header
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.app-header-logo {
		margin-right: 2 * $app-sidebar-gap;

		[data-kt-app-sidebar-enabled="true"] & {
			width: $app-sidebar-base-width;
			margin-right: $app-sidebar-gap;
		}
	}

	.app-header-menu {
		.menu {
			& > .menu-item {
				position: relative; 
				margin-right: 2.3rem !important; 
				padding-left: 0 !important;
				margin-left: 0 !important;				 

				// Customize menu link default state       
				@include menu-link-default-state( 
					$title-color: var(--#{$prefix}gray-700), 
					$icon-color: null, 
					$bullet-color: null, 
					$arrow-color: null, 
					$bg-color: null, 
					$all-links: false
				);

				// Customize menu link hover state       
				@include menu-link-hover-state( 
					$title-color: var(--#{$prefix}gray-900), 
					$icon-color: null, 
					$bullet-color: null, 
					$arrow-color: null, 
					$bg-color: transparent, 
					$all-links: false
				);

				// Customize menu link here state       
				@include menu-link-here-state( 
					$title-color: var(--#{$prefix}gray-900), 
					$icon-color: null,
					$bullet-color: null, 
					$arrow-color: null,
					$bg-color: transparent, 
					$all-links: false
				);

				// Customize menu link show state       
				@include menu-link-show-state( 
					$title-color: var(--#{$prefix}gray-900), 
					$icon-color: null, 
					$bullet-color: null, 
					$arrow-color: null, 
					$bg-color: transparent, 
					$all-links: false
				);

				// Customize menu link active state       
				@include menu-link-active-state( 
					$title-color: null, 
					$icon-color: null, 
					$bullet-color: null, 
					$arrow-color: null, 
					$bg-color: transparent, 
					$all-links: false
				);

				> .menu-link {
					background-color: transparent !important;
					padding: 0 !important;
					margin-bottom: 8px;
					margin-left: 0 !important;
					font-weight: $font-weight-bold;
					font-size: 1.15rem;
				} 

				// States
                &.here > .menu-link,
                & > .menu-link.active {                     
                    &:after {
                        position: absolute;						                                                   
                        bottom: 0;
                        left: 0;                        
                        width: 100%;
                        height: 2px;                        
                        content: " ";
                        background-color: var(--#{$prefix}gray-900);      
						@include border-radius(20px);                   
                    }                     				
                }    
			}
		}
	}

	[data-kt-app-header-sticky="on"] {
		.app-header {
			background-color: transparent;
			box-shadow: none;
			
			$offset: divide($app-container-padding-x, 2);	

			.app-container {
				padding-left: $offset !important;	
				padding-right: $offset !important;	
			}

			.app-header-wrapper {
				@include border-bottom-radius($card-border-radius);
				background-color: var(--#{$prefix}app-header-sticky-bg-color);
				box-shadow: var(--#{$prefix}app-header-sticky-box-shadow);	
				padding-left: $offset;	
				padding-right: $offset;
			}
		}	
	}
}